<di-navbar #navbar="vudNavbar">
  <di-navbar-brand data-testid="visma-menu" role="menu">
    <div class="d-inline-flex justify-content-between align-items-center">
      <span class="d-inline-flex payroll-icon align-items-center"></span>
      <span>Payroll</span>
    </div>
  </di-navbar-brand>
  @for (menuItem of menuItems$ | async; track menuItem) {
    <di-navbar-brand-option
      [href]="
        menuItem.url +
        '/' +
        (menuItem.webApplicationUri ?? '') +
        '/?switchApplicationModule=' +
        environment.empManModuleId +
        '&contextSelector:switchContext=' +
        (userCompany$ | async).id +
        '&orgId=' +
        (userCompany$ | async).id
      "
      [label]="menuItem.name"
    >
    </di-navbar-brand-option>
  }
  <di-navbar-brand-option label="Payroll" [active]="true"></di-navbar-brand-option>
  <di-navbar-menu>
    <di-navbar-menu-item
      class="virtual"
      label="Payroll"
      [href]="environment.vismaAboutUrl"
      i18n-label="@@header-navbar.payroll"
    >
    </di-navbar-menu-item>
    <di-navbar-menu-item
      label="Employees"
      routerLink="/employees"
      routerLinkActive
      i18n-label="@@header-navbar.employees"
    >
      <di-navbar-secondary-menu>
        <di-navbar-menu-item
          label="Employee list"
          routerLink="/employees"
          routerLinkActive
          i18n-label="@@header-navbar.employees-list"
        ></di-navbar-menu-item>
        <di-navbar-menu-item
          label="Employee card"
          routerLink="/employee"
          routerLinkActive
          i18n-label="@@header-navbar.employee-card"
        ></di-navbar-menu-item>
        <di-navbar-menu-item
          label="Fixed transactions"
          routerLink="/fixed-transactions"
          routerLinkActive
          [isHidden]="isFixedTransactionListHidden"
          i18n-label="@@header-navbar.fixed-transaction"
        ></di-navbar-menu-item>
        <di-navbar-menu-item
          label="History"
          routerLink="/history"
          routerLinkActive
          i18n-label="@@header-navbar.history"
        ></di-navbar-menu-item>
        <di-navbar-menu-item
          label="Import vacation balances"
          routerLink="calendar/vacation-balance-import"
          routerLinkActive
          [isDisabled]="isVacationBalanceDisabled"
          [tooltipText]="functionDisabledTooltip"
          i18n-label="@@header-navbar.import-vacation-balances"
        >
        </di-navbar-menu-item>
        <di-navbar-menu-item
          label="Import absences"
          routerLink="calendar/absence-import"
          routerLinkActive
          [isDisabled]="isAbsenceImportDisabled"
          [tooltipText]="functionDisabledTooltip"
          i18n-label="@@header-navbar.import-absences"
        ></di-navbar-menu-item>

        <di-navbar-menu-item
          iconClass="vismaicon vismaicon-export vismaicon-sm ml-4"
          label="Go to previous version of Employees"
          [href]="openLegacyEM()"
          i18n-label="@@header-navbar.previous-version-employees"
        ></di-navbar-menu-item>
      </di-navbar-secondary-menu>
    </di-navbar-menu-item>
    <di-navbar-menu-item
      label="Payroll portal"
      routerLink="/payroll-portal"
      routerLinkActive
      *diIsFeatureDisabled="'disable-payroll-portal-tab'"
      i18n-label="@@header-navbar.payroll-portal"
    >
    </di-navbar-menu-item>
    <di-navbar-menu-item
      label="Reports"
      [href]="environment.vismaAboutUrl + (userCompany$ | async).id + '/reports'"
      i18n-label="@@header-navbar.reports"
    >
    </di-navbar-menu-item>
    <di-navbar-menu-item label="Settings" routerLink="/settings" routerLinkActive i18n-label="@@header-navbar.settings">
    </di-navbar-menu-item>
  </di-navbar-menu>
  @if (user$ | async; as user) {
    <di-navbar-right-menu>
      <li class="icon" id="liveLeader-launcher" *diIsFeatureEnabled="'enable-liveleader'">
        <a (click)="openLiveLeader()">
          <span class="vismaicon vismaicon-menu vismaicon-chat" data-testid="liveleader-chat"></span>
        </a>
      </li>
      <li class="icon" id="walkme-launcher" *diIsFeatureDisabled="'disable-walkme'">
        <a (click)="openWalkme()">
          <span class="vismaicon vismaicon-menu vismaicon-help" data-testid="feedback-icon"></span>
        </a>
      </li>
      <li class="icon">
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLScM0v7zEve0kS3juyegAhi_gik_CMetBbsTDPui6TGQeZXwZQ/viewform"
          aria-label="Send us feedback"
          rel="noopener noreferrer"
          target="_blank"
        >
          <span class="vismaicon vismaicon-menu vismaicon-feedback" data-testid="feedback-icon"></span>
        </a>
      </li>
      @if (userCompany$ | async; as userCompany) {
        <li
          class="dropdown user-dropdown"
          vudDropdown
          #dropdown="vudDropdown"
          [class.icon]="navbar.isTablet$ | async"
          [class.open]="dropdown.isOpen$ | async"
        >
          <a
            class="dropdown-toggle mw-300"
            data-testid="user-menu"
            role="menu"
            vudDropdownToggle
            (click)="dropdown.toggle()"
          >
            {{ user.firstName }} {{ user.lastName }}
            <span class="vismaicon vismaicon-menu vismaicon-user"></span>
            <small title="{{ userCompany.name }}">{{ userCompany.name }}</small>
            <span class="caret caret-right"></span>
          </a>
          <ul class="dropdown-menu company-selection" data-testid="company-selection-menu" vudDropdownMenu>
            <li class="user-details-area no-overflow search-wrapper">
              <vud-select
                data-testId="company-selector"
                bindLabel="name"
                [clearable]="false"
                [items]="user.availableContexts"
                [value]="userCompany.name"
                (change)="selectCompany($event)"
              >
                <ng-template vudNotfoundTmp>
                  <div class="ng-option">
                    <span class="text-grey" i18n="@@header.No-items-found-text">Company was not found</span>
                  </div>
                </ng-template>
              </vud-select>
            </li>
            <li class="user-details-area">
              <div>
                <div class="user-text no-image">
                  <span> {{ user.firstName }} {{ user.lastName }}</span>
                  <span class="text-disabled width-auto" title="{{ user.emailAddress }}">{{ user.emailAddress }}</span>
                </div>
              </div>
            </li>
            <li class="divider"></li>
            <li>
              @if (!environment.production) {
                <a (click)="changeLanguage()" i18n="@@header.language"> Change language </a>
              }
              <a (click)="goToAbout()" i18n="@@header.about">About</a>
            </li>
            <li class="divider divider-strong"></li>
            <li class="context-footer company-selection-footer clear">
              <a
                class="log-out-link vismaicon-sm vismaicon-dynamic vismaicon vismaicon-logout"
                [href]="environment.signinUrl + '?msgCode=LOGOUT'"
                (click)="logout()"
                i18n="@@header.logout"
                >Log out</a
              >
            </li>
          </ul>
        </li>
      }
    </di-navbar-right-menu>
  }
</di-navbar>
<ng-template #functionDisabledTooltip>
  <span i18n="@@header.function-disabled-tooltip"
    >Sorry, this function is temporarily disabled. Please try again later.</span
  >
</ng-template>
