import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UserSessionService } from '../../core/services/user-session.service';

@Component({
  selector: 'di-beta',
  templateUrl: './beta.component.html',
  styleUrls: ['./beta.component.scss'],
})
export class BetaComponent implements OnInit {
  toggleOffText = $localize`:@@Beta.Toggle-off-text:Member of Beta Program - please enable to access the benefits of Beta Program and see the latest features of Bulk Operations.`;
  toggleOnText = $localize`:@@Beta.Toggle-on-text:Member of Beta Program`;
  betaProgramOn: boolean;
  navigateToSalariesEditUrl: string;

  ngOnInit(): void {
    this.betaProgramOn = this.isInLocalStorage();
  }

  constructor(
    private userSessionService: UserSessionService,
    private router: Router,
  ) {
    this.navigateToSalariesEditUrl =
      this.router.getCurrentNavigation()?.extras.state?.navigateToSalariesEditUrl;
  }

  changeProgram(): void {
    this.betaProgramOn = !this.betaProgramOn;
    const betaCompanies = JSON.parse(localStorage.getItem('betaCompanies')) || [];
    const existsInArray = betaCompanies.some(
      (companyId) => companyId === this.userSessionService.companyId,
    );
    if (existsInArray) {
      if (!this.betaProgramOn) {
        betaCompanies.splice(betaCompanies.indexOf(this.userSessionService.companyId), 1);
        localStorage.setItem('betaCompanies', JSON.stringify(betaCompanies));
      }
    } else {
      betaCompanies.push(this.userSessionService.companyId);
      localStorage.setItem('betaCompanies', JSON.stringify(betaCompanies));
    }
    // this.userSessionService.switchBetaMode(this.betaProgramOn);
  }

  isInLocalStorage() {
    const betaCompanies = JSON.parse(localStorage.getItem('betaCompanies')) || [];
    return betaCompanies.some((companyId) => companyId === this.userSessionService.companyId);
  }

  openFeedbackForm(): void {
    window.location.href =
      'https://docs.google.com/forms/d/e/1FAIpQLSc2rFAbIDW7YVjOS1NNi3eBxGFB8XdDe7vUf2dPerxS-2Kp1Q/viewform';
  }
}
