<div class="container">
  <h2 i18n="@@file-import-grid.title">Import from file</h2>
  <div class="grid-margins d-flex flex-wrap">
    <di-grid-card iconClass="grid-vb-icon" routerLink="/calendar/vacation-balance">
      <di-grid-card-title i18n="@@file-import-grid.vacation-balances-title">Vacation balances</di-grid-card-title>
      <di-grid-card-desc i18n="@@file-import-grid.vacation-balances-desc"
        >Use a vacation balance file to do the import in Visma Payroll</di-grid-card-desc
      >
    </di-grid-card>
    <di-grid-card
      iconClass="grid-vb-icon"
      [isExternalLink]="true"
      (click)="navigateTo('https://wagerun.cj.hrm.stag.visma.net/about')"
    >
      <di-grid-card-title>External link test</di-grid-card-title>
      <di-grid-card-desc>It should open a new tab with about page</di-grid-card-desc>
    </di-grid-card>
  </div>
</div>
