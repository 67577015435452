import { toServerDuration, relativeToClocks, generateUUID } from '@datadog/browser-core';
import { createPerformanceObservable, RumPerformanceEntryType } from '../../browser/performanceObservable';
export function startLongAnimationFrameCollection(lifeCycle, configuration) {
  var performanceResourceSubscription = createPerformanceObservable(configuration, {
    type: RumPerformanceEntryType.LONG_ANIMATION_FRAME,
    buffered: true
  }).subscribe(function (entries) {
    for (var _i = 0, entries_1 = entries; _i < entries_1.length; _i++) {
      var entry = entries_1[_i];
      var startClocks = relativeToClocks(entry.startTime);
      var rawRumEvent = {
        date: startClocks.timeStamp,
        long_task: {
          id: generateUUID(),
          entry_type: "long-animation-frame" /* RumLongTaskEntryType.LONG_ANIMATION_FRAME */,
          duration: toServerDuration(entry.duration),
          blocking_duration: toServerDuration(entry.blockingDuration),
          first_ui_event_timestamp: toServerDuration(entry.firstUIEventTimestamp),
          render_start: toServerDuration(entry.renderStart),
          style_and_layout_start: toServerDuration(entry.styleAndLayoutStart),
          scripts: entry.scripts.map(function (script) {
            return {
              duration: toServerDuration(script.duration),
              pause_duration: toServerDuration(script.pauseDuration),
              forced_style_and_layout_duration: toServerDuration(script.forcedStyleAndLayoutDuration),
              start_time: toServerDuration(script.startTime),
              execution_start: toServerDuration(script.executionStart),
              source_url: script.sourceURL,
              source_function_name: script.sourceFunctionName,
              source_char_position: script.sourceCharPosition,
              invoker: script.invoker,
              invoker_type: script.invokerType,
              window_attribution: script.windowAttribution
            };
          })
        },
        type: "long_task" /* RumEventType.LONG_TASK */,
        _dd: {
          discarded: false
        }
      };
      lifeCycle.notify(11 /* LifeCycleEventType.RAW_RUM_EVENT_COLLECTED */, {
        rawRumEvent: rawRumEvent,
        startTime: startClocks.relative,
        domainContext: {
          performanceEntry: entry
        }
      });
    }
  });
  return {
    stop: function () {
      return performanceResourceSubscription.unsubscribe();
    }
  };
}
