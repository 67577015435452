<div class="container">
  <h1 i18n>Settings</h1>
  <div class="grid-margins d-flex flex-wrap">
    <di-grid-card
      iconClass="grid-payslip-icon"
      [isExternalLink]="true"
      (click)="navigateToExternal('#reports/payslip-configuration/view', true)"
    >
      <di-grid-card-title i18n="@@settings-grid.payslip-configuration-title">Payslip configuration</di-grid-card-title>
      <di-grid-card-desc i18n="@@settings-grid.payslip-configuration-desc"
        >Possiblity to adjust the layout of the payslips sent to the employees
      </di-grid-card-desc>
    </di-grid-card>
    <di-grid-card
      iconClass="grid-wageruns-icon"
      [isExternalLink]="true"
      (click)="navigateToExternal('#configuration/wagerun-definition', true)"
    >
      <di-grid-card-title i18n="@@settings-grid.wageruns-title">Wageruns</di-grid-card-title>
      <di-grid-card-desc i18n="@@settings-grid.wageruns-desc">Overview of the recurring wageruns</di-grid-card-desc>
    </di-grid-card>
    <di-grid-card
      iconClass="grid-paycodes-icon"
      [isExternalLink]="true"
      (click)="navigateToExternal('#configuration/paycode', true)"
    >
      <di-grid-card-title i18n="@@settings-grid.paycodes-title">Paycodes</di-grid-card-title>
      <di-grid-card-desc i18n="@@settings-grid.paycodes-desc">Edit or delete paycodes</di-grid-card-desc>
    </di-grid-card>
    <di-grid-card
      iconClass="grid-company-icon"
      [isExternalLink]="true"
      (click)="navigateToExternal('/settings/company')"
    >
      <di-grid-card-title i18n="@@settings-grid.company-title">Company</di-grid-card-title>
      <di-grid-card-desc i18n="@@settings-grid.company-desc"
        >General information and settings for the company
      </di-grid-card-desc>
    </di-grid-card>
    <di-grid-card
      iconClass="grid-creditors-icon"
      [isExternalLink]="true"
      (click)="navigateToExternal('/settings/creditors')"
    >
      <di-grid-card-title i18n="@@settings-grid.creditors-title">Creditors</di-grid-card-title>
      <di-grid-card-desc i18n="@@settings-grid.creditors-desc">Add, edit or delete creditors</di-grid-card-desc>
    </di-grid-card>
    <di-grid-card iconClass="grid-unions-icon" [isExternalLink]="true" (click)="navigateToExternal('/settings/unions')">
      <di-grid-card-title i18n="@@settings-grid.unions-title">Unions</di-grid-card-title>
      <di-grid-card-desc i18n="@@settings-grid.unions-desc">Add, edit or delete unions</di-grid-card-desc>
    </di-grid-card>
    <di-grid-card
      iconClass="grid-pension-icon"
      [isExternalLink]="true"
      (click)="navigateToExternal('/settings/pension')"
    >
      <di-grid-card-title i18n="@@settings-grid.pension-title">Pension</di-grid-card-title>
      <di-grid-card-desc i18n="@@settings-grid.pension-desc">Add, edit or delete pension providers</di-grid-card-desc>
    </di-grid-card>
    <di-grid-card
      iconClass="grid-accounts-icon"
      [isExternalLink]="true"
      (click)="navigateToExternal('/settings/accounts')"
    >
      <di-grid-card-title i18n="@@settings-grid.accounts-title">Accounts</di-grid-card-title>
      <di-grid-card-desc i18n="@@settings-grid.accounts-desc">Add, edit or delete accounts</di-grid-card-desc>
    </di-grid-card>
    <di-grid-card
      iconClass="grid-acc-types-icon"
      [isExternalLink]="true"
      (click)="navigateToExternal('/settings/accountType')"
    >
      <di-grid-card-title i18n="@@settings-grid.account-types-title">Account types</di-grid-card-title>
      <di-grid-card-desc i18n="@@settings-grid.account-types-desc">Add, edit or delete account types</di-grid-card-desc>
    </di-grid-card>
    <di-grid-card
      iconClass="grid-acc-dimension-icon"
      *diIsFeatureEnabled="'disable-cost-units-management'"
      [isExternalLink]="true"
      (clickCard)="navigateToExternal('/settings/accountingDimension')"
    >
      <di-grid-card-title
        ><p class="mb-0" i18n="@@settings-grid.accounting-dimensions-title">Accounting dimensions</p>
        <p i18n="@@settings-grid.accounting-dimensions-previous-service">(Previous service)</p></di-grid-card-title
      >
      <di-grid-card-desc i18n="@@settings-grid.accounting-dimensions-desc"
        >Add, edit or delete accounting dimension values in the previous version of the service.
      </di-grid-card-desc>
    </di-grid-card>
    <di-grid-card
      iconClass="grid-acc-dimension-icon"
      *diIsFeatureDisabled="'disable-cost-units-management'"
      (clickCard)="navigateTo('settings/cost-units')"
    >
      <di-grid-card-title i18n="@@settings-grid.cost-units-managment">Cost unit management</di-grid-card-title>
      <di-grid-card-desc i18n="@@settings-grid.cost-units-desc">Add, edit or delete cost units</di-grid-card-desc>
    </di-grid-card>
    <di-grid-card iconClass="grid-cars-icon" [isExternalLink]="true" (clickCard)="navigateToExternal('/settings/cars')">
      <di-grid-card-title i18n="@@settings-grid.cars-title">Cars</di-grid-card-title>
      <di-grid-card-desc i18n="@@settings-grid.cars-desc"
        >Add, edit or delete the company car register
      </di-grid-card-desc>
    </di-grid-card>
    <di-grid-card
      iconClass="grid-worktime-agreements-icon"
      [isExternalLink]="true"
      (clickCard)="navigateToExternal('/settings/workTimeAgreements')"
    >
      <di-grid-card-title i18n="@@settings-grid.work-time-agreements-title">Work time agreements</di-grid-card-title>
      <di-grid-card-desc i18n="@@settings-grid.work-time-agreements-desc"
        >Add, edit or delete work time agreements
      </di-grid-card-desc>
    </di-grid-card>
    <di-grid-card
      iconClass="grid-tags-icon"
      [isCardDisabled]="isTagsDisabled$ | async"
      (clickCard)="navigateTo('settings/company/tags')"
    >
      <di-grid-card-title i18n="@@settings-grid.tags-title">Tags</di-grid-card-title>
      <di-grid-card-desc i18n="@@settings-grid.tags-desc">Add, edit or delete tags</di-grid-card-desc>
    </di-grid-card>
  </div>
</div>
