<div class="d-flex flex-column flex-1">
  <di-header></di-header>
  <div class="d-flex flex-column flex-1">
    <div
      class="flex-1"
      data-testId="page-wrapper"
      [ngClass]="{
        'hidden-vert-menu': (isMenuCollapsed$ | async),
        'page-holder': isSideMenuAvailable$ | async,
        'page-holder-nav-collapse': (isIconMenu$ | async) && (isMenuCollapsed$ | async),
      }"
    >
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<di-toaster></di-toaster>
