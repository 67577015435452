<div class="container h-100 d-flex flex-column align-items-center error-text text-default">
  <span class="error-img mb-40 mt-96" data-testid="error-img" title="Service unavailable"></span>
  <span class="mb-24" i18n="@@service-unavailable.title"
    >The service is temporarily unavailable. Please try again later.</span
  >
  <span class="mb-40" data-testid="service-unavailable" role="note" i18n="@@service-unavailable.text"
    >Please go to
    <a href="https://status.visma.com" role="link" rel="noreferrer noopener" target="_blank">Visma status page</a>
    to see updates on the issue status</span
  >
</div>
