<div class="container mt-24">
  <div class="row error-page align-items-center">
    <div class="error-page-content col-12 col-md-6">
      <h1 i18n="@@authentication-error.title">An authentication error occured</h1>
      <p i18n="@@authentication-error.description">
        We're sorry, an unexpected authentication error has occurred and it has been logged. We will try to fix it as
        soon as possible.
      </p>
      <button class="btn btn-primary" (click)="backToHome()" i18n="@@button.back-to-visma-net-home">
        Back to Visma.net Home
      </button>
    </div>
    <div class="error-page-image col-12 col-md-6">
      <img
        src="../../../assets/icons/server_error.svg"
        alt="An error has occured"
        i18n-alt="@@alt-text.error-occured"
      />
    </div>
  </div>
</div>
