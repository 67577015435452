<div class="container pt-24">
  <div class="row error-page align-items-center">
    <div class="error-page-content col-12 col-md-6">
      <h1 i18n="@@access-denied.title">Access denied</h1>
      <p i18n="@@access-denied.description">
        You are trying to view a page you are not allowed to see. If you are not logged in, log in. Otherwise, check
        that your account should be able to view it.
      </p>
      <button class="btn btn-primary" (click)="backToHome()" i18n="@@access-denied.back-button">
        Back to Visma.net Home
      </button>
    </div>
    <div class="error-page-image col-12 col-md-6">
      <img src="../../../assets/icons/error-access-denied.svg" alt="Access denied" />
    </div>
  </div>
</div>
